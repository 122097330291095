<script lang="ts" setup>
const { src, alt, variant } = defineProps<{
  src: string
  alt: string
  variant: 'circle' | 'square'
}>()

const error = ref(false)
const isCircle = computed(() => variant === 'circle')

const onError = () => {
  error.value = true
}
</script>

<template>
  <span
    :class="isCircle ? 'rounded-full' : 'rounded-xl'"
    class="aspect-square w-full drop-shadow-[0_6px_10px_rgba(0,0,0,0.04)] bg-white border border-zeno-gray-border-light inline-block"
  >
    <NuxtImg
      v-if="!error"
      :key="src"
      :alt="alt"
      :class="isCircle ? 'rounded-full' : 'rounded-xl'"
      :src="src"
      class="w-full h-full"
      loading="lazy"
      @error="onError"
    />
  </span>
</template>

<style scoped></style>
